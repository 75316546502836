@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  /* font smoothing */
  body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .cw-bg-brand-gradient {
    background: linear-gradient(180deg, #006147 0%, #003f2e 100%);
  }
}

@layer utilities {
  /* https://www.figma.com/file/b5RwJNcAsP3f7CWbKFZxMc/%5B-%F0%9F%93%90-Spec%5D-Chat-%2B-Calendar?type=design&node-id=1679%3A80351&mode=design&t=6CKcDFRLmRTqzDA8-1 */
  .cw-bg-subtle-striped {
    background-image: linear-gradient(
      135deg,
      #fafafa 27.78%,
      #f1f1f1 27.78%,
      #f1f1f1 50%,
      #fafafa 50%,
      #fafafa 77.78%,
      #f1f1f1 77.78%,
      #f1f1f1 100%
    );
    background-size: 12.73px 12.73px;
  }

  .cw-bg-light-subtle-striped {
    background-image: linear-gradient(
      135deg,
      #fafafa 27.78%,
      #f6f6f6 27.78%,
      #f6f6f6 50%,
      #fafafa 50%,
      #fafafa 77.78%,
      #f6f6f6 77.78%,
      #f6f6f6 100%
    );
    background-size: 12.73px 12.73px;
  }

  /* Tailwind doesn't support this... yet. T_T */
  .cw-scrollbar-gutter-stable {
    scrollbar-gutter: stable;
  }
  .cw-scrollbar-width-thin {
    scrollbar-width: thin;
  }

  /*
   * Force outline on elements that have previously had
   * outline: none applied.
   * Credit: https://css-tricks.com/copy-the-browsers-native-focus-styles/
   */
  .cw-outline-override-none {
    outline: 5px auto Highlight !important;
    outline: 5px auto -webkit-focus-ring-color !important;
  }
}
